$SITE_COLOR: #659dbd; // ffd1dc
$TEXT_COLOR: #fbeec1; // 5959c5
$DIALOG_TEXT_COLOR: #7d7f7d; // 5959c5
$BUTTON_COLOR: #7d7f7d; // ffd1dc
$LINK_COLOR: #fbeec1; // 5959c5;
$VALIDATION_COLOR: red; // orangered;
$DIALOG_VALIDATION_COLOR: orangered;

@mixin transition($time) {
  -webkit-transition: all $time ease 0s;
  -moz-transition: all $time ease 0s;
  -o-transition: all $time ease 0s;
  transition: all $time ease 0s;
}