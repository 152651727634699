@import "src/scss/common";

.init__app__dialog {
  background-color: white !important;
}

.dialog__shadow {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 50;
}

.dialog {
  width: 450px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  max-width: calc(100% - 20px);
  background-color: white;
  padding: 20px;
  border-radius: 4px;

  & p, label {
    color: $DIALOG_TEXT_COLOR;
  }
}

.dialog-close {
  position: absolute;
  top: 10px;
  right: 10px;
  height: 30px;
  width: 30px;
  padding: 5px;
  cursor: pointer;
  background-color: white;

  &:hover {
    transform: scale(1.1);
  }
}

.tray-dialog {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 10px;
  pointer-events: none;
  align-items: flex-start;
  z-index: 60;
}

.tray-dialog-item {
  display: flex;
  font-size: 16px;
  padding: 15px 20px;
  border-radius: 4px;
  margin-top: 10px;
  color: white;
  background-color: green;
  box-shadow: 0 0 20px green;
  border: 1px solid green;
  animation: animationAppear 0.5s ease;

  &.error {
    background-color: red;
    border: 1px solid red;
    box-shadow: 0 0 20px red;
  }
}

.dialog-validation-text {
  font-size: 14px;
  color: $DIALOG_VALIDATION_COLOR !important;
  margin-bottom: 5px;
}

.send-row {
  display: flex;
  gap: 10px;
}

.send-button {
  font-size: 16px;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 4px;
  color: white;
  background-color: $BUTTON_COLOR;
  @include transition(0.3s);

  &:hover {
    opacity: 0.7;
  }
}

.radio-block {
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-top: 15px;
  margin-bottom: 15px;
}

.appeal-reason-radio {
  display: flex;
  align-items: center;
  gap: 5px;

  & label {
    font-size: 16px;
  }
}

.text-field {
  flex-basis: 100%;
  padding: 9px;
  font-size: 16px;
  border: 1px solid lightgray;
  border-radius: 4px;
  box-shadow: inset 0 0 3px lightgray;
  color: $DIALOG_TEXT_COLOR;
  @include transition(0.3s);

  &::placeholder {
    color: lightgray;
  }
}

.text-area {
  width: 100%;
  padding: 9px;
  font-size: 16px;
  border: 1px solid lightgray;
  border-radius: 4px;
  box-shadow: inset 0 0 3px lightgray;
  color: $DIALOG_TEXT_COLOR;
  resize: none;
  @include transition(0.3s);

  &::placeholder {
    color: lightgray;
  }
}

.tg-notification-text {
  font-size: 12px;
  //color: $DIALOG_VALIDATION_COLOR !important;
}

@media (max-width: 425px) {
  .radio-block label {
    font-size: 14px;
  }

  .text-field {
    min-width: 100%;
    font-size: 14px;
  }

  .text-area {
    font-size: 14px;
  }

  .send-row {
    flex-wrap: wrap;
  }

  .send-button {
    font-size: 14px;
  }
}