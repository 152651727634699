@import "nullable", "common", "app", "components/spinner", "components/dialog";

body {
  margin: 0;
  font-family: sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body.lock {
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

html, body {
  height: 100%;
}

p, label {
  color: $TEXT_COLOR;
}

.d_flex {
  display: flex;
}

.ai-center {
  align-items: center;
}

.fs_14 {
  font-size: 14px;
}

.fs_16 {
  font-size: 16px;
}

.fs_18 {
  font-size: 18px;
}

.fs_26 {
  font-size: 26px;
}

.mb_5 {
  margin-bottom: 5px;
}

.mb_10 {
  margin-bottom: 10px;
}

.mb_15 {
  margin-bottom: 15px;
}

.mb_20 {
  margin-bottom: 20px;
}

.mb_30 {
  margin-bottom: 30px;
}

.mb_40 {
  margin-bottom: 40px;
}

.mb_50 {
  margin-bottom: 50px;
}