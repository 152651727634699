$firstColor: #00334e;
$secondColor: #004d73;
$thirdColor: #007fdb;
$forthColor: #dbebfa;

@keyframes animationMove {
  0% {
    transform: translate(12px, 80px) scale(0);
  }
  25% {
    transform: translate(12px, 80px) scale(0);
  }
  50% {
    transform: translate(12px, 80px) scale(1);
  }
  75% {
    transform: translate(80px, 80px) scale(1);
  }
  100% {
    transform: translate(148px, 80px) scale(1);
  }
}

@keyframes animationHide {
  0% {
    transform: translate(148px, 80px) scale(1);
  }
  100% {
    transform: translate(148px, 80px) scale(0);
  }
}

@keyframes animationColor {
  0% {
    background: $secondColor
  }
  25% {
    background: $forthColor
  }
  50% {
    background: $thirdColor
  }
  75% {
    background: $firstColor
  }
  100% {
    background: $secondColor
  }
}

.spinner {
  width: 200px;
  height: 200px;
  display: inline-block;
  overflow: hidden;
  background: none;

  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.spinner__row {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}

.spinner__row div {
  position: absolute;
  box-sizing: content-box;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  transform: translate(80px, 80px) scale(1);
  background: $secondColor;
  animation: animationMove 2.2222222222222223s infinite cubic-bezier(0, 0.5, 0.5, 1);
}

.spinner__row div:nth-child(1) {
  background: $firstColor;
  transform: translate(148px, 80px) scale(1);
  animation: animationHide 0.5555555555555556s infinite cubic-bezier(0, 0.5, 0.5, 1), animationColor 2.2222222222222223s infinite step-start;
}

.spinner__row div:nth-child(2) {
  animation-delay: -0.5555555555555556s;
  background: $secondColor;
}

.spinner__row div:nth-child(3) {
  animation-delay: -1.1111111111111112s;
  background: $firstColor;
}

.spinner__row div:nth-child(4) {
  animation-delay: -1.6666666666666665s;
  background: $thirdColor;
}

.spinner__row div:nth-child(5) {
  animation-delay: -2.2222222222222223s;
  background: $forthColor;
}