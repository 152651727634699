* {
  padding: 0;
  margin: 0;
  border: 0;
}

*, *:before, *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

html, body {
  height: 100%;
  width: 100%;
  line-height: 1;
  font-size: 14px;
  -ms-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
}

:focus, :active {
  outline: none;
}

nav, footer, header, aside {
  display: block;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: bold;
}

h1 {
  font-size: 36px;
}

h2 {
  font-size: 32px;
}

h3 {
  font-size: 28px;
}

h4 {
  font-size: 24px;
}

h5 {
  font-size: 20px;
}

h6 {
  font-size: 18px;
}

button {
  cursor: pointer;
}

button::-moz-focus-inner {
  padding: 0;
  border: 0;
}

input, button, textarea {
  font-family: inherit;
}

input::-ms-clear {
  display: none;
}

a, a:visited {
  color: inherit;
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

a:focus, a:active {
  outline: none;
}

ul li {
  list-style: none;
}

img {
  vertical-align: top;
}