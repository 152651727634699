@import "common";

#root {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  background-color: $SITE_COLOR;
  overflow: hidden;
}

.container {
  width: 100%;
  max-width: 1024px;
  min-width: 300px;
  margin: 0 auto;
  padding: 20px;
}

.notification-text {
  font-size: 14px;
  margin-bottom: 20px;
}

.info-block {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 30px;
  align-items: center;
  gap: 30px;
}

.photo {
  flex: 0 0 40%;

  & img {
    border-radius: 50%;
    width: 100%;
    height: 100%;
    object-fit: cover;
    overflow: hidden;
  }
}

.price-block {
  display: flex;
  flex: 1 0 10%;
  flex-direction: column;
  max-width: 520px;
  gap: 10px;

  & p {
    font-size: 18px;
  }
}

.price-row {
  display: flex;
  justify-content: space-between;
  gap: 30px;
  align-items: center;
}

.profile-block {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 10px;
}

.button-row {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}

.button {
  min-width: 300px;
  font-size: 16px;
  padding: 10px 10px;
  cursor: pointer;
  border-radius: 4px;
  color: white;
  background-color: $BUTTON_COLOR;
  @include transition(0.3s);

  &:hover {
    opacity: 0.7;
  }
}

.checkbox {
  width: 16px;
  background-color: #0075ff;
  padding: 2px;
  border-radius: 2px;
}

.link {
  text-decoration: underline;
  color: $LINK_COLOR;
}

.validation-text {
  font-size: 14px;
  color: $VALIDATION_COLOR;
  margin-bottom: 5px;
}

@media (max-width: 800px) {
  .price-block p {
    font-size: 16px;
  }
}

@media (max-width: 700px) {
  .info-block {
    flex-direction: column;
    flex-wrap: wrap;
    align-items: flex-start;
  }

  .photo {
    width: 100%;
  }

  .price-block {
    order: 3;
  }

  .profile-block {
    gap: 5px;
    order: 2;
  }

  .button {
    min-width: 220px;
    flex-grow: 1;
  }
}

@media (max-width: 500px) {
  .button-row {
    gap: 10px;
  }

  .notification-text {
    font-size: 12px;
    margin-bottom: 10px;
  }
}